<template
>
  <div class="survey">
    <div class="row">
      <div class="col-lg-12" v-if="!isLoading">
        <div class="widget">
          <div class="widget-body">
            <div class="row">
              <div class="col-12 br-1 position-relative">
                <div class="row bb-1 pb-3 mb-3">

                  <div class="col-6 d-flex align-items-center">
                    <h5 style="font-size: 1rem;">{{ $t('labels.statistics') }}</h5>
                  </div>

                  <div class="col-6 d-flex justify-content-end">
                    <v-guard :permissions="['court-export']">
                      <b-dropdown v-if="!isDownloading" id="dropdown-1">
                        <template v-slot:button-content><i class="fas fa-download"></i> Exportera</template>

                        <b-dropdown-group id="dropdown-group-1" header="Format">
                          <b-dropdown-item-button @click="$refs.witnesslist.exportResultsEstimate('witness', 'excel')">Excel
                          </b-dropdown-item-button>
                          <!-- <b-dropdown-item-button @click="$refs.witnesslist.exportResultsEstimate('witness', 'csv')">CSV
                          </b-dropdown-item-button> -->
                        </b-dropdown-group>
                      </b-dropdown>
                      <button v-if="isDownloading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> Vänta
                      </button>
                    </v-guard>
                  </div>

                </div>



                <div
                    v-if="informationFieldWait"
                    style="
										width: 100%;
										height: 100%;
										text-align: center;
										padding-top: 30%;
										font-size: 20px;
										background-color: #fff;
										opacity: 0.6;
										z-index: 100;
									"
                    class="position-absolute"
                >
                  <i class="fas fa-circle-notch fa-spin"></i>
                </div>

                <reactive-base
                    app="witness_index"
                    :credentials="user.user.es_username + ':' + user.user.es_password"
                    :url="elasticUrl"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="widget">
                        <div class="widget-header statistics-filter">
                          <div class="widget-header-label">
                            <h3>{{ $t('labels.selected-filters') }}</h3>
                          </div>
                          <div class="widget-header-toolbar">
                            <div class="actions">
                              <SelectedFilters class="bb-1 curent-filters pl-4 m-0" :clearAllLabel="this.$t('labels.clear')"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <v-guard :permissions="['court-page-statistics-use-filter']">
                      <div class="col-12">
                        <div class="widget">
                          <div class="widget-header statistics-filter">
                            <multi-list
                                componentId="yearFilter"
                                :filterLabel="this.$t('labels.year')"
                                :title="this.$t('labels.year')"
                                dataField="year.raw"
                                className="horizontal-filter"
                                sortBy="asc"
                                :showSearch="false"
                                :URLParams="true"
                                :react="{ and: getOtherFilterIds('yearFilter') }"
                                @valueChange="setSelectedYears"
                            >
                              <template slot="renderItem" slot-scope="{ label }">
                                <div>{{ label }}</div>
                              </template>
                            </multi-list>
                            <multi-list
                                componentId="quarterFilter"
                                :filterLabel="this.$t('labels.quarter')"
                                :title="this.$t('labels.quarter')"
                                dataField="quarter.raw"
                                className="horizontal-filter"
                                sortBy="asc"
                                :showSearch="false"
                                :URLParams="true"
                                :react="{ and: getOtherFilterIds('quarterFilter') }"
                            >
                              <template slot="renderItem" slot-scope="{ label }">
                                <div>
                                  {{ label }}
                                </div>
                              </template>
                            </multi-list>
                            <multi-list
                                componentId="monthFilter"
                                :filterLabel="this.$t('labels.month')"
                                :title="this.$t('labels.month')"
                                dataField="month.raw"
                                className="horizontal-filter"
                                sortBy="asc"
                                :size="12"
                                :showSearch="false"
                                :URLParams="true"
                                :transformData="sortMonthByLabel"
                                :react="{ and: getOtherFilterIds('monthFilter') }"
                            >
                              <template slot="renderItem" slot-scope="{ label, count }">
                                <div>
                                  {{ renameMonthsLabel(label) }}
                                </div>
                              </template>
                            </multi-list
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-xl-3 order-lg-1 order-xl-1">
                        <div class="widget widget-facets statistics-vertical">
                          <div class="widget-header mb-3">
                            <div class="widget-header-label">
                              <h3><i class="fas fa-circle-notch fa-spin" v-show="isLoading"></i>{{ $t('labels.filtration') }}</h3>
                            </div>
                            <div class="widget-header-toolbar">
                              <div class="actions"></div>
                            </div>
                          </div>
                          <div class="widget-body p-0">
                            <multi-dropdown-list
                              className="filter-vertical"
                              :URLParams="true"
                              :showSearch="true"
                              :placeholder="this.$t('labels.select')"
                              sortBy="asc"
                              title="Säkerhetssal"
                              componentId="testimonySecurityRoomFilter"
                              filterLabel="Säkerhetssal"
                              :dataField="'testimony_security_room.raw'"
                              :react="{ and: getOtherFilterIds('testimonySecurityRoomFilter') }"
                          />
                          </div>
                          <div class="widget-body p-0">
                            <multi-dropdown-list
                              className="filter-vertical"
                              :URLParams="true"
                              :showSearch="true"
                              :placeholder="this.$t('labels.select')"
                              sortBy="asc"
                              title="Typ av kontakt"
                              componentId="courtContactTypeFilter"
                              filterLabel="Typ av kontakt"
                              :dataField="'court_contact_type.raw'"
                              :transformData="customSortAge"
                              :react="{ and: getOtherFilterIds('courtContactTypeFilter') }"
                            />
                          </div>
                          <div class="widget-body p-0">
                            <multi-dropdown-list
                              className="filter-vertical"
                              :URLParams="true"
                              :showSearch="true"
                              :placeholder="this.$t('labels.select')"
                              sortBy="asc"
                              componentId="testimonySupportFilter"
                              filterLabel="Stödinsatsen"
                              title="Stödinsatsen"
                              nestedField="testimony_support"
                              :dataField="'testimony_support.label.raw'"
                              :react="{ and: getOtherFilterIds('testimonySupportFilter') }"
                            />
                          </div>
                          <div class="widget-body p-0">
                            <multi-dropdown-list
                              className="filter-vertical"
                              :URLParams="true"
                              :showSearch="true"
                              :placeholder="this.$t('labels.select')"
                              sortBy="asc"
                              title="Externt mål"
                              componentId="externalCaseFilter"
                              filterLabel="Externt mål"
                              :dataField="'external_case.raw'"
                              :react="{ and: getOtherFilterIds('externalCaseFilter') }"
                            />
                          </div>
                          <div class="widget-body p-0">
                            <multi-dropdown-list
                              className="filter-vertical"
                              :URLParams="true"
                              :showSearch="true"
                              :placeholder="this.$t('labels.select')"
                              sortBy="asc"
                              componentId="crimesFilter"
                              :title="this.$t('labels.crime')"
                              :filterLabel="this.$t('labels.crime')"
                              :dataField="this.$field('crime')+'.raw'"
                              :react="{ and: getOtherFilterIds('crimesFilter') }"
                            />
                          </div>
                          <div class="widget-body p-0">
                            <multi-dropdown-list
                              className="filter-vertical"
                              :URLParams="true"
                              :showSearch="true"
                              :placeholder="this.$t('labels.select')"
                              sortBy="asc"
                              title="Typ av mål"
                              componentId="testimonyTypeSubFilter"
                              filterLabel="Typ av mål"
                              :dataField="'testimony_type.raw'"
                              :react="{ and: getOtherFilterIds('testimonyTypeSubFilter') }"
                            />
                          </div>
                          <div class="widget-body p-0">
                            <multi-dropdown-list
                              className="filter-vertical"
                              :URLParams="true"
                              :showSearch="true"
                              :placeholder="this.$t('labels.select')"
                              sortBy="asc"
                              componentId="officeFilter"
                              :title="this.$t('labels.victim-support-centre')"
                              :filterLabel="this.$t('labels.victim-support-centre')"
                              dataField="office.raw"
                              :react="{ and: getOtherFilterIds('officeFilter') }"
                            />
                          </div>
                          <div class="widget-body p-0">
                            <multi-dropdown-list
                              className="filter-vertical"
                              :URLParams="true"
                              :showSearch="true"
                              :placeholder="this.$t('labels.select')"
                              sortBy="asc"
                              componentId="localofficeFilter"
                              :filterLabel="this.$t('labels.local-office')"
                              :title="this.$t('labels.local-office')"
                              dataField="local_office.raw"
                              :react="{ and: getOtherFilterIds('localofficeFilter') }"
                            />
                          </div>
                          <div class="widget-body p-0">
                            <multi-dropdown-list
                              className="filter-vertical"
                              :URLParams="true"
                              :showSearch="true"
                              :placeholder="this.$t('labels.select')"
                              sortBy="asc"
                              componentId="userSubFilter"
                              :title="this.$t('labels.administrator')"
                              :filterLabel="this.$t('labels.administrator')"
                              nestedField="users"
                              dataField="users.name.raw"
                              :react="{ and: getOtherFilterIds('userSubFilter') }"
                            />
                          </div>
                        </div>
                      </div>
                    </v-guard>
                    <div class="col-lg-12 col-xl-9 order-lg-1 order-xl-1">
                      <reactive-component
                          componentId="myColorPicker"
                          :URLParams="true"
                          :react="{ and: getOtherFilterIds('') }"
                          :defaultQuery="
                          () => ({
                            runtime_mappings: {
                                'date.month_of_years': {
                                  type: 'long',
                                  script: 'emit(doc[\'created_at\'].value.getMonthValue())'
                                }
                              },
                              aggs: {
                                crimes: {
                                  terms: {
                                    field: 'crime.raw',
                                    order: {
                                      _key: 'asc'
                                    },
                                    size: 100
                                  },
                                  aggs: {
                                    year: {
                                      terms: {
                                        field: 'year.raw',
                                        order: {
                                          _key: 'asc'
                                        },
                                        min_doc_count: 0,
                                        size: 100
                                      }
                                    }
                                  }
                                },
                                office: {
                                  terms: {
                                    field: 'office.raw',
                                    order: {
                                      _key: 'asc'
                                    },
                                    size: 100
                                  },
                                  aggs: {
                                    year: {
                                      terms: {
                                        field: 'year.raw',
                                        order: {
                                          _key: 'asc'
                                        },
                                        min_doc_count: 0,
                                        size: 100
                                      }
                                    }
                                  }
                                },
                                localoffice: {
                                  terms: {
                                    field: 'local_office.raw',
                                    order: {
                                      _key: 'asc'
                                    },
                                    size: 100
                                  },
                                  aggs: {
                                    year: {
                                      terms: {
                                        field: 'year.raw',
                                        order: {
                                          _key: 'asc'
                                        },
                                        min_doc_count: 0,
                                        size: 100
                                      }
                                    }
                                  }
                                },
                                testimony_security_room: {
                                  terms: {
                                    field: 'testimony_security_room.raw',
                                    order: {
                                      _key: 'asc'
                                    }
                                  },
                                  aggs: {
                                    year: {
                                      terms: {
                                        field: 'year.raw',
                                        order: {
                                          _key: 'asc'
                                        },
                                        min_doc_count: 0,
                                        size: 100
                                      }
                                    }
                                  }
                                },
                                court_contact_type: {
                                  terms: {
                                    field: 'court_contact_type.raw',
                                    order: {
                                      _key: 'asc'
                                    }
                                  },
                                  aggs: {
                                    year: {
                                      terms: {
                                        field: 'year.raw',
                                        order: {
                                          _key: 'asc'
                                        },
                                        min_doc_count: 0,
                                        size: 100
                                      }
                                    }
                                  }
                                },
                                month: {
                                  terms: {
                                    field: 'date.month_of_years',
                                    order: {
                                      _key: 'asc'
                                    },
                                    size: 100
                                  },
                                  aggs: {
                                    year: {
                                      terms: {
                                        script: 'if (doc[\'created_at\'].size() != 0) doc[\'created_at\'].value.getYear()',
                                        order: {
                                          _key: 'asc'
                                        },
                                        min_doc_count: 0,
                                        size: 100
                                      }
                                    }
                                  }
                                },
                                quarter: {
                                  terms: {
                                    field: 'quarter.raw',
                                    order: {
                                      _key: 'asc'
                                    }
                                  },
                                  aggs: {
                                    year: {
                                      terms: {
                                        field: 'year.raw',
                                        order: {
                                          _key: 'asc'
                                        },
                                        min_doc_count: 0,
                                        size: 100
                                      }
                                    }
                                  }
                                },
                                users: {
                                  nested: {
                                    path: 'users'
                                  },
                                  aggs: {
                                    user: {
                                      terms: {
                                        field: 'users.name.raw',
                                        order: {
                                          _key: 'asc'
                                        },
                                        size: 100
                                      },
                                      aggs: {
                                        reverted: {
                                          reverse_nested: {},
                                          aggs: {
                                            year: {
                                              terms: {
                                                field: 'year.raw',
                                                order: {
                                                  _key: 'asc'
                                                },
                                                min_doc_count: 0,
                                                size: 100
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                },
                                testimony_support: {
                                  nested: {
                                    path: 'testimony_support'
                                  },
                                  aggs: {
                                    relation: {
                                      terms: {
                                        field: 'testimony_support.label.raw',
                                        order: {
                                          _key: 'asc'
                                        },
                                        size: 100
                                      },
                                      aggs: {
                                        reverted: {
                                          reverse_nested: {},
                                          aggs: {
                                            year: {
                                              terms: {
                                                field: 'year.raw',
                                                order: {
                                                  _key: 'asc'
                                                },
                                                min_doc_count: 0,
                                                size: 100
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                },
                                persons: {
                                  nested: {
                                    path: 'persons'
                                  },
                                      aggs: {
                                        reverted: {
                                          reverse_nested: {},
                                          aggs: {
                                            year: {
                                              terms: {
                                                field: 'year.raw',
                                                order: {
                                                  _key: 'asc'
                                                },
                                                min_doc_count: 0,
                                                size: 100
                                              }
                                            }
                                          }
                                    }
                                  }
                                },
                                external_case: {
                                  terms: {
                                    field: 'external_case.raw',
                                    order: {
                                      _key: 'asc'
                                    }
                                  },
                                  aggs: {
                                    year: {
                                      terms: {
                                        field: 'year.raw',
                                        order: {
                                          _key: 'asc'
                                        },
                                        min_doc_count: 0,
                                        size: 100
                                      }
                                    }
                                  }
                                },
                                testimony_type: {
                                  terms: {
                                    field: 'testimony_type.raw',
                                    order: {
                                      _key: 'asc'
                                    }
                                  },
                                  aggs: {
                                    year: {
                                      terms: {
                                        field: 'year.raw',
                                        order: {
                                          _key: 'asc'
                                        },
                                        min_doc_count: 0,
                                        size: 100
                                      }
                                    }
                                  }
                                }
                              }
                          })
                        "
                      >
                        <div slot-scope="{ aggregations, hits, setQuery }">
                          <!-- {{aggregations}} -->
                          <div class="row">
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.quarter.buckets" :infoBox="''" :title="$t('labels.quarter')"></statistic-widget>
                            </div>
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.month.buckets" :infoBox="''" :title="$t('labels.month')"></statistic-widget>
                            </div>
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.crimes.buckets" :infoBox="''" :title="$t('labels.crime')"></statistic-widget>
                            </div>
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.testimony_security_room.buckets" :infoBox="''" title="Säkerhetssal">></statistic-widget>
                            </div>
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.court_contact_type.buckets" :infoBox="''" title="Typ av kontakt">></statistic-widget>
                            </div>
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.testimony_support.relation.buckets" :infoBox="''" title="Stödinsatsen"></statistic-widget>
                            </div>
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.office.buckets" :infoBox="''" :title="$t('labels.victim-support-centre')"></statistic-widget>
                            </div>
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.localoffice.buckets" :infoBox="''" :title="$t('labels.local-office')"></statistic-widget>
                            </div>
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.external_case.buckets" :infoBox="''" title="Externt mål"></statistic-widget>
                            </div>
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.testimony_type.buckets" :infoBox="''" title="Typ av mål"></statistic-widget>
                            </div>
                            <div class="col-12">
                              <statistic-widget :yearsSelected="yearsSelected" :data="aggregations.users.user.buckets" :infoBox="'Ärenden avd Statistik - aggregerat fält Handläggare rubrik'" :title="$t('labels.administrator')"></statistic-widget>
                            </div>
                            <!-- <div class="col-12">
                              <statistic-widget-hierarchical :yearsSelected="yearsSelected" :data="aggregations.actions.action_parent.buckets" title="Händelser"></statistic-widget-hierarchical>
                            </div>
                            <div class="col-12">
                              <statistic-widget-action-time :yearsSelected="yearsSelected" :data="aggregations.actiontime.buckets" title="Nedlagd tid"></statistic-widget-action-time>
                            </div> -->
                          </div>
                        </div>
                      </reactive-component>
                    </div>
                  </div>
                </reactive-base>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import StatisticWidget from '@/components/widgets/StatisticWidget.vue';
import StatisticWidgetActionTime from '@/components/widgets/StatisticWidgetActionTime.vue';
import statisticWidgetHierarchical from '@/components/widgets/statisticWidgetHierarchical.vue';
import InfoBox from '@/components/InfoBox';

export default {
  components: {
    StatisticWidget,
		StatisticWidgetActionTime,
		statisticWidgetHierarchical,
		InfoBox
  },
  data() {
		return {
			elasticUrl: process.env.VUE_APP_API_ELASTIC,
			getSortBy: 'id',
			isLoading: false,
      informationFieldWait: false,
      isDownloading: false,
			allMonths: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'Maj',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Okt',
				'Nov',
				'Dec'
			],
			customAge: [
				'Barn 0-11',
				'Ungdom 12-17',
				'Ung vuxen 18-25',
				'Vuxen 26-64',
				'Äldre 65 +',
				'Uppgift saknas'
			],
			facets: [
				'yearFilter',
				'monthFilter',
				'quarterFilter',
				'courtContactTypeFilter',
				'testimonySecurityRoomFilter',
				'riskFilter',
				'officeFilter',
				'crimesFilter',
				'testimonySupportFilter',
				'ticketstatusFilter',
				'testimonyTypeSubFilter',
				'municipalityFilter',
				'localofficeFilter',
				'userSubFilter',
				'externalCaseFilter',
				'actionSubFilter',
				'specialEventSubFilter'
			],
			yearsSelected: [],
      query: {},
		};
	},
  computed: {
		user() {
			return this.$store.getters.user;
		},
	},
  methods: {
    renameMonthsLabel(label) {
			label = this.allMonths[this.allMonths.indexOf(label.slice(0,3))] ?? '';

			return label;
		},
		sortMonthByLabel(data) {
			let months = data.slice().sort((a, b) => {
				return this.allMonths.indexOf(a.key.slice(0,3)) - this.allMonths.indexOf(b.key.slice(0,3));
			});

			return months;
		},
		customSortAge(data) {
			let newData = data.slice().sort((a, b) => {
				return this.customAge.indexOf(a.key) - this.customAge.indexOf(b.key);
			});

			return newData;
		},
		getOtherFilterIds(id) {
			let ids = this.facets.filter((f) => f !== id).map((f) => f);

			return ids;
		},
		setSelectedYears(y) {
			if (y !== undefined && y.length) {
				this.yearsSelected = y;
			} else {
				this.yearsSelected = [];
			}
		},
  },
};
</script>
